import React, { forwardRef } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import UserInfo from 'components/UserInfo';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import SidenavAdmin from './SidenavAdmin';
import SidenavLocal from './SidenavLocal';
import SidenavComunicacao from './SidenavComunicacao';
import SidenavTecnico from './SidenavTecnico';

class SideNav extends React.PureComponent {

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
  }

  render() {
    const {navCollapsed, drawerType, width, navigationStyle} = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    var local = sessionStorage.getItem('sublocal')
    var cargo = sessionStorage.getItem('cargo')
    
    var SideContent = forwardRef((props,ref)=><div {...props} ref={ref}></div>)
    switch (cargo) {
      case 'local':
        SideContent = forwardRef((props,ref)=><SidenavLocal {...props} ref={ref}/>)
      break;
      case 'geral':
        SideContent = forwardRef((props,ref)=><SidenavContent {...props} ref={ref}/>)
      break;
      case 'admin':
        SideContent = forwardRef((props,ref)=><SidenavAdmin {...props} ref={ref}/>)
      break;
      case 'comunicacao':
        SideContent = forwardRef((props,ref)=><SidenavComunicacao {...props} ref={ref}/>)
      break;
      case 'tecnico':
        SideContent = forwardRef((props,ref)=><SidenavTecnico {...props} ref={ref}/>)
      break;
      default:
      break;
    }
    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer className="app-sidebar-content"
                variant={type}
                open={type.includes('temporary') ? navCollapsed : true}
                onClose={this.onToggleCollapsedNav}
                classes={{
                  paper: 'side-nav',
                }}
        >
          <UserInfo/>
          <SideContent local={local}/>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navCollapsed, drawerType, width, navigationStyle} = settings;
  return {navCollapsed, drawerType, width, navigationStyle}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideNav));

