import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {
    BELOW_THE_HEADER,
    HORIZONTAL_NAVIGATION
} from 'constants/ActionTypes';
import AppNotification from '../AppNotification/index';
import {switchLanguage, toggleCollapsedNav} from 'actions/Setting';
import Button from '@material-ui/core/Button';
import {userSignOut} from 'actions/Auth';
import { Badge, Hidden, Modal, Paper } from '@material-ui/core';
import { LaConFetch } from '../Functions/fetch';

class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    })
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    })
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    })
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      warning: false,
      flag:0,
      faltaAgendas:0,
      firstLoad:false,
    }
  }

  componentDidMount(){
    this.loadFlag()
  }

  loadFlag=()=>{
    LaConFetch(`api/vacina/flags/faltaacao`,(r)=>{
      if(r.success){
        this.setState({
          //flag:r.flag,
          //faltaAgendas:r.agenda,
          //firstLoad: r.flag > 0 || r.agenda > 0,
        })
      }
    },()=>{})
  }
  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  feedback = (i) =>{
    this.setState({
      warning: i,
    });
  }

  render() {
    const { navigationStyle, horizontalNavPosition} = this.props;
    const {warning,flag,firstLoad,faltaAgendas} =this.state;

    return (
      <AppBar
        className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
        <Toolbar className="app-toolbar bg-primary" disableGutters={false}>
          <Hidden lgUp>
            <IconButton onClick={this.onToggleCollapsedNav}><i className='zmdi zmdi-menu text-white'/></IconButton>
          </Hidden>
          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item app-tour">
              <Badge badgeContent={flag} color='primary'>
                <IconButton className='icon-btn bg-white' onClick={()=>this.props.history.push('/app/diario')}>
                  <i className='zmdi zmdi-alert-triangle text-red'/>
                </IconButton>
              </Badge>
            </li>
            <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.appNotification}
                toggle={this.onAppNotificationSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn bg-white">
                    <i className={warning ? ("zmdi zmdi-notifications-none text-red icon-alert"):("zmdi zmdi-notifications-none text-red")}/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <AppNotification feedback={this.feedback}/>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
          
          <Button onClick={()=>{this.props.userSignOut()}} className='text-white'>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw"/>
            <span>Sair</span>
          </Button>
          <div className="ellipse-shape"></div>
        </Toolbar>
        <Modal open={firstLoad} onBackdropClick={()=>this.setState({firstLoad:false})} style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
          <div>
            <Paper className='p-2'>
              <div>
                {flag > 0?<h4 className='text-center'>Há {flag} vacinações que faltam ser marcadas como vacinado ou não vacinado.</h4>:null}
                {faltaAgendas > 0?<h4 className='text-center'>{faltaAgendas} agendamentos faltam confirmar presença.</h4>:null}
                <div className='clearfix'>
                  <div className='float-left'>
                    <Button onClick={()=>this.setState({firstLoad:false})} className='jr-btn bg-danger text-white'>
                      Fechar
                    </Button>
                  </div>
                  <div className='float-right'>
                    <Button onClick={()=>this.props.history.push('/app/diario')} className='jr-btn bg-success text-white'>
                      Ir ao menu
                    </Button>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        </Modal>
      </AppBar>
    );
  }

}


const mapStateToProps = ({settings}) => {
  const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, locale, navigationStyle, horizontalNavPosition}
};

export default withRouter(connect(mapStateToProps, {userSignOut, toggleCollapsedNav, switchLanguage})(Header));