import React from 'react'
import {Scrollbars} from 'react-custom-scrollbars';

function renderView({ style, ...props }) {
    const viewStyle = {
        padding: 15,
        backgroundColor: `rgb(0, 0, 0)`,
        color: `rgb(0, 0, 0)`
    };
    return (
        <div
            className="box"
            style={{ ...style, ...viewStyle }}
            {...props}/>
    );
}

function renderThumb({ style, ...props }) {
    const thumbStyle = {
        backgroundColor: `rgb(200, 200, 200)`
    };
    return (
        <div
            style={{ ...style, ...thumbStyle }}
            {...props}/>
    );
}

const CustomScrollbars = (props) => <Scrollbars  {...props} autoHide 
    //renderView={renderView}
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
/>;

export default CustomScrollbars;